<template>
  <section>
    <v-row class="mb-5 mr-0 ml-0">
      <v-tabs color="primary darken-1" centered icons-and-text v-model="tab" show-arrows grow>
        <v-tab class="flex-grow-1 pl-3 pr-3" @click="$router.push({name: 'trustedUsersList'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('my_trusted_title') }}
          <v-icon>mdi-account-key-outline</v-icon>
        </v-tab>
        <v-tab class="flex-grow-1 pl-3 pr-3" @click="$router.push({name: 'entrustedUsersList'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('entrusted_to_me_title') }}
          <v-icon>mdi-account-switch-outline</v-icon>
        </v-tab>
      </v-tabs>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      tab: this.$route.meta.index
    }
  }
}
</script>
